<template>
    <div class="content_container installment_lease">
		<div class="content">
			<div class="banner">
				<h1>할부리스</h1>
			</div>
			<ul class="common_tab">
				<li>
					<router-link to="/product/LeaseNormal">일반 리스</router-link>
				</li>
				<li class="current">
					<router-link to="/product/LeaseFinance">할부 금융</router-link>
				</li>
			</ul>
			<h2 class="title_product">할부 금융</h2>
			<section class="overview"> 
				<h3>개요</h3>
				<ul>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/installment_lease/installment_lease_overview_icon01.svg" alt="Installment Finance" />
							<p>Installment<br />Finance</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/installment_lease/installment_lease_overview_icon02.svg" alt="Equipment Repayment" />
							<p>Equipment<br />Repayment</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/installment_lease/installment_lease_overview_icon03.svg" alt="Low First Cost" />
							<p>Low First<br />Cost</p>
						</div>
					</li>
				</ul>
				<div class="textBox">
					<p>할부금융은 제품(용역포함) 구입시 금융회사가 구매자를 대신하여 제품 구입대금을 제조ㆍ판매자에게 일시 결제하여 주고, 구매자가 원하는 기간에 분할상환 하실 수 있습니다.
					</p>
				</div>
			</section>
			<section class="structure">
				<h3>운용구조</h3>
				<div class="structure_infogp">
					<p><img src="@/assets/images/sub/installment_lease/installment_lease_structure_infogp.png" alt="무궁화캐피탈 일반리스 운용구조" /></p>
				</div>	
			</section>

			<section class="product_list">
				<h3>할부 취급 품목</h3>
				<table>
					<colgroup>
						<col class="col_th">
					</colgroup>
					<tr>
						<th>내구소비재</th>
						<td>
							<span>가구, 영상ㆍ음향장치, 사무용기기, 오락기기, 통신기기, 냉난방기, 농업용기계, 자동판매기기, 자동차, 특수자동차, 선박 등 운송기계, 침구ㆍ수예품, 의복, 장신구, 주방용품 등</span>
						</td>
					</tr>
					<tr>
						<th>주택</th>
						<td>
							<span>계약금 납부를 완료한 신규 완공주택이나 주택건설사업자가 건설중인 주택</span>
						</td>
					</tr>
					<tr>
						<th>기계</th>
						<td>
							<span>건설기계, 의료기계, 섬유기계, 공작기계, 인쇄기계 등</span>
						</td>
					</tr>
					<tr>
						<th>기타</th>
						<td>
							<span>용역/서비스</span>
						</td>
					</tr>
				</table>
			</section>

			<section class="comparison">
				<h3>할부 금융과 일반 대출 비교</h3>
				<table>
					<colgroup>
						<col class="col_th">
					</colgroup>
					<tr>
						<th>구분</th>
						<th>할부 금융</th>
						<th>일반 대출</th>
						<th>비고</th>
					</tr>
					<tr>
						<th>대상자</th>
						<td>물품구매자</td>
						<td>가계소비자</td>
						<td>할부금융은 구매계약이 전제</td>
					</tr>
					<tr>
						<th>계약 관계</th>
						<td>3면 계약 (판매자, 구매자, 할부금융사)</td>
						<td>양자 계약 (소비자, 금융기관)</td>
						<td></td>
					</tr>
					<tr>
						<th>대출금 지급</th>
						<td>제조ㆍ판매자</td>
						<td>대출신청자</td>
						<td></td>
					</tr>
					<tr>
						<th>보증 등</th>
						<td>주로 신용대출</td>
						<td>신용 또는 담보 대출</td>
						<td></td>
					</tr>
					<tr>
						<th>계약 업무</th>
						<td>통상 판매자가 대출계약 서류 징구업무 대행</td>
						<td>대출금융기관에서 직접 계약</td>
						<td></td>
					</tr>
					<tr>
						<th>대출 절차</th>
						<td>간편</td>
						<td>각종 서류 징구 등 절차 번잡</td>
						<td>물품구입과 동시에 계약체결</td>
					</tr>
				</table>
			</section>
			
			<Online />
		</div>
	</div>
</template>

<script>
import Online from "@/components/Online.vue"

export default {
    name : "InvestPrivate",
	components: { 
		Online
	},
}
</script>

